<template>
    <div>
        <Header :id="id" :exhibitionId="exhibitionId"></Header>
        <div class="spbanner">
            <img :src="conmpany.indexThumb" alt="">
        </div>
        <div class="main" v-if="videoData">
            <div class="info">
                <div class="title">Power Cable Workshop</div>
                <div class="list">
                    <div class="left">
                        <div class="bigvideo">
                            <video :src="bigVideo" controls="controls"></video>
                        </div>
                        <div class="smallvideo">
                            <div class="small" v-for="(item,index) in videoData" :key="index">
                                <video :src="item.url" controls="controls" @click="srcChange(item.url)"></video>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="rtitle">
                            <a-icon type="message" class="message"/>&nbsp;
                            <router-link :to="{ name: 'Talking' ,params: { adminId: id } }"> Chat with exhibitor</router-link>
                        </div>
                        <div class="rinfo">
                         <!--    <div class="rmeg">
                                <div class="mleft">zhanshang:</div>
                                <div class="mright">Hi  what is name？</div>
                            </div>
                            <div class="rmeg">
                                <div class="mleft">jack:</div>
                                <div class="mright">  Hi what is name Hi what is name is Hi what is name</div>
                            </div>-->
                        </div> 
                        <div class="rbot">
                            <div class="boleft"><input type="text" placeholder="Talk to  exhibitor..."/></div>
                            <div class="boright">
                              <router-link :to="{ name: 'Talking' ,params: { adminId: id } }">Send</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            <div class="contact">
                <div class="tag"></div>
                <div class="us">Exhibitor recommended products</div>
            </div> 
             <div class="pro1">
                <div class="duct1" v-for="(item,index) in products" :key="index">
                    <div class="proimg">
                      <router-link :to="{ name: 'Productdetail', params: { id: id, productId: item.productId } }">
                          <img :src="item.thumb" alt="">
                      </router-link>
                    </div>
                    <div class="protxt">
                    {{item.productName}}
                    </div>
                    <div class="protxt2">
                        {{ $t('FOBPrice') }}: <span><span>{{item.minPrice}}</span></span>
                    </div>
                    <div class="protxt3">{{ $t('Min.Order') }}: {{item.minOrder}}</div>
                    <div class="rtxt6">
                      <div class="rtxt5">{{ $t('contact.now') }}</div>
                      <div class="rheart red" v-if="item.isLike == 1" @click="unlike(item.productId)"><a-icon type="heart" /></div>
                      <div class="rheart" v-else @click="like(item.productId)"><a-icon type="heart" /></div>
                    </div>
                </div>
            </div>
            <Contact></Contact> 
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "../components/Footer";
import Contact from "./components/Contact";

export default {
  name: "VirtualMeeting",
  inject: ['reload'], // 注入依赖
  components: {
    Footer,
    Header,
    Contact
  },
  data() {
    return {
        exhibitionId: this.$route.params.exhibitionId,
        id: this.$route.params.id,
        exhi: [],
        products: [],
        videos: [],
        conmpany: [],
        videoData: [],
        bigVideo: ''
    }
  },
  mounted () {
       this.getList()
       this.getConmpany()
       this.getVideo()
  },
  methods: {
    srcChange(videosrc){
        this.bigVideo = videosrc
    },
    // 获取首页详情
    getList () {
      this.$common.fetchList('/company/index?companyId='+this.id, {}).then(data => {
        if (data.code === 200) {
         const result = data.data
          for(const [k, v] of Object.entries(result)) {
            this[k] = v
          }
          console.log(this.products)
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
     // 获取公司详情
    getConmpany () {
      this.$common.fetchList('/company/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.conmpany = result
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
     // 获取直播s视频
    getVideo () {
      this.$common.fetchList('/company/video/lists?companyId='+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.datas
          this.videoData = result
          this.bigVideo = this.videoData[0].url
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
     //收藏
    like(productId) {
      this.$common.handlePost('/index/product/like', {productId:productId}).then(data => {
      if (data.code === 200) {
          this.$layer.alert(data.msg);
          this.reload()
      }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    unlike (productId) {
      this.$common.handlePost('/index/product/unlike', {productId:productId}).then(data => {
      if (data.code === 200) {
          this.$layer.alert(data.msg);
          this.reload()
      }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    }
  }
}
</script>


<style lang="less" scoped>
  @import '~@/assets/css/spmeeting.less';
</style>